
import { Component, Watch } from 'vue-property-decorator'
import Emitter from '@/utils/emitter'
import { isWechat } from '@/utils/utils'

import { formatDate } from '@/utils/date'
import { log } from 'console'

@Component({
	components: {},
	filters: {
		formatDate(value: number) {
			return value ? formatDate('yyyy-MM-dd h:i:s', value) : '-'
		}
	}
})
export default class extends Emitter {
	public $refs!: {
		'audio-success': HTMLAudioElement
		'audio-fail': HTMLAudioElement
	}
	private isWechat = false
	private packageWeight = 0
	private list: any[] = []
	private editForm = {
		packageCode: '',
		boxCode: '',
		check_rule: 1,
		stockOutCode: ''
	}
	private boxData = {
		status: 2
	}
	async created() {
		const { box_code = '' }: any = this.$route.query
		this.isWechat = isWechat()
		this.editForm.boxCode = box_code
		if (box_code) {
			this.getBoxDetail()
		} else {
			await this.createBox()
		}
	}
	async initJSSdk() {
		if (!this.isWechat) return false
		const url = location.href.split('#')[0]
		const { data } = await this.$axios.post('v1/jobline/js-sdk-config', {
			url
		})
		this.$wx.config({
			debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
			...data,
			jsApiList: ['scanQRCode'] // 必填，需要使用的JS接口列表
		})
	}
	async scanBarcode() {
		if (!this.$wx) return false
		await this.initJSSdk()
		this.$wx.ready(() => {
			this.$wx.scanQRCode({
				needResult: 1,
				scanType: ['barCode'],
				success: (res: any) => {
					const result = res.resultStr.split(',')
					this.editForm.packageCode = result[result.length - 1]
					this.addPackage()
				}
			})
		})
	}
	async emptyBox() {
		const isConfirm = await this.$confirm('确认清空此箱？', '提示', {
			confirmButtonText: '确认',
			cancelButtonText: '取消'
		}).catch(() => false)
		if (isConfirm) {
			await this.$axios
				.post(`/v1/jobline/box/truncate/${this.editForm.boxCode}`)
				.catch(() => false)
			this.list = []
		}
	}
	async voidItem({ row, $index }: any) {
		const isConfirm = await this.$confirm('确认删除？', '提示', {
			confirmButtonText: '确认',
			cancelButtonText: '取消'
		}).catch(() => false)
		if (!isConfirm) return false
		const { package_attr_num } = row
		const { boxCode } = this.editForm
		const { data } = await this.$axios
			.post(`v1/jobline/box/remove/${boxCode}`, {
				package_attr_num
			})
			.catch(() => {
				return { data: null }
			})
		if (data) {
			this.packageWeight -= this.list[$index].weight || 0
			this.list.splice($index, 1)
		}
	}

	printLabel(code: string) {
		const baseUrl = process.env.VUE_APP_API_BASE_URL
		return `${baseUrl}v1/jobline/generate/package-bar-code/${code}?title=箱`
	}
	async addPackage() {
		const { packageCode, boxCode, check_rule } = this.editForm
		if (!packageCode) {
			return this.$message.error('包裹单号不能为空')
		}
		const { data } = await this.$axios
			.post(`/v1/jobline/box/${boxCode}`, {
				package_attr_num: packageCode,
				check_rule
			})
			.catch(e => {
				let message = ''
				const { data } = e.response || {}
				if (data && data.message) message = data.message
				if (Array.isArray(message)) message = message[0]
				message = message.replace('_', ' ')
				this.$notify.error({
					title: '错误',
					message: `${packageCode} ${message}`,
					duration: 1000 * 5
				})
				this.editForm.packageCode = ''
				this.$refs['audio-fail'].play()
				return { data: null }
			})
		if (!data) return false
		this.list.unshift(data)
		this.packageWeight += data.weight || 0
		this.editForm.packageCode = ''
		this.$message.success('添加成功')
		this.$refs['audio-success'].play()
	}
	async createBox() {
		const { data } = await this.$axios.post('/v1/jobline/box', {
			note: '1',
			express_id: 2
		})
		this.editForm.boxCode = data
		this.$router
			.replace({
				query: {
					box_code: data
				}
			})
			.catch(err => err)
	}
	async getBoxDetail() {
		const { data } = await this.$axios.get(
			`/v1/jobline/box/${this.editForm.boxCode}`
		)
		this.list = data.package_attrs
		for (let index = 0; index < this.list.length; index++) {
			const element = this.list[index]
			this.packageWeight += element.weight || 0
		}
		this.boxData = data
	}
}

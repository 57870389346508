var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "z-page",
    [
      _c(
        "el-breadcrumb",
        {
          staticStyle: { "margin-top": "20px" },
          attrs: { "separator-class": "el-icon-arrow-right" },
        },
        [
          _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
            _vm._v("首页"),
          ]),
          _c("el-breadcrumb-item", { attrs: { to: { path: "/warehouse" } } }, [
            _vm._v("仓储"),
          ]),
          _c("el-breadcrumb-item", [
            _vm._v("包裹装箱: " + _vm._s(this.editForm.boxCode)),
          ]),
        ],
        1
      ),
      _c("div", { staticClass: "content" }, [
        _c("div", [
          _c(
            "div",
            { staticClass: "container info-card" },
            [
              _c(
                "el-form",
                {
                  ref: "search-form",
                  staticClass: "search-form",
                  attrs: { inline: true, model: _vm.editForm },
                  nativeOn: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return (() => {}).apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "包裹单号/箱子编号" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "包裹单号/箱子编号",
                          clearable: "",
                        },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.addPackage.apply(null, arguments)
                          },
                        },
                        model: {
                          value: _vm.editForm.packageCode,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.editForm,
                              "packageCode",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "editForm.packageCode",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "是否开启渠道排货" } },
                    [
                      _c("el-switch", {
                        attrs: {
                          "active-color": "#13ce66",
                          "active-value": 1,
                          "inactive-value": 0,
                          "inactive-color": "#ff4949",
                          "active-text": "启用",
                          "inactive-text": "关闭",
                        },
                        model: {
                          value: _vm.editForm.check_rule,
                          callback: function ($$v) {
                            _vm.$set(_vm.editForm, "check_rule", $$v)
                          },
                          expression: "editForm.check_rule",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "z-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.addPackage },
                        },
                        [_vm._v("添加")]
                      ),
                      _vm.isWechat
                        ? _c(
                            "z-button",
                            {
                              attrs: { type: "default" },
                              on: { click: _vm.scanBarcode },
                            },
                            [_vm._v("扫码")]
                          )
                        : _vm._e(),
                      _c(
                        "el-link",
                        {
                          staticClass: "z-button-link",
                          attrs: {
                            underline: false,
                            target: "_blank",
                            href: _vm.printLabel(_vm.editForm.boxCode),
                          },
                        },
                        [
                          _vm.editForm.boxCode
                            ? _c(
                                "z-button",
                                { attrs: { type: "primary", plain: "" } },
                                [
                                  _c(
                                    "svg",
                                    {
                                      staticClass: "icon",
                                      attrs: { "aria-hidden": "true" },
                                    },
                                    [
                                      _c("use", {
                                        attrs: {
                                          "xlink:href": "#icon-icon_btn_print",
                                        },
                                      }),
                                    ]
                                  ),
                                  _vm._v("打印"),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "ctrl-header" },
            [
              _vm.boxData.status == 2
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "danger", size: "medium", plain: "" },
                      on: { click: _vm.emptyBox },
                    },
                    [
                      _c(
                        "svg",
                        {
                          staticClass: "icon",
                          attrs: { "aria-hidden": "true" },
                        },
                        [
                          _c("use", {
                            attrs: { "xlink:href": "#icon-icon_btn_deletable" },
                          }),
                        ]
                      ),
                      _vm._v("清空列表"),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "container info-card" },
            [
              _c(
                "el-table",
                { staticStyle: { width: "100%" }, attrs: { data: _vm.list } },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "package_attr_num",
                      label: "包裹单号",
                      width: "190",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-link",
                              {
                                attrs: {
                                  type: "primary",
                                  underline: false,
                                  href: `/dashboard?logistics_code=${scope.row.package_attr_num}`,
                                },
                              },
                              [_vm._v(_vm._s(scope.row.package_attr_num))]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "status_pay",
                      width: "120",
                      label: "包裹状态",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.status === 1
                              ? _c(
                                  "el-tag",
                                  {
                                    attrs: { type: "success", size: "medium" },
                                  },
                                  [_vm._v("正常")]
                                )
                              : _c(
                                  "el-tag",
                                  { attrs: { type: "danger", size: "medium" } },
                                  [_vm._v("异常")]
                                ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "status_pay",
                      width: "120",
                      label: "支付状态",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.status_pay === 1
                              ? _c(
                                  "el-tag",
                                  {
                                    attrs: { type: "success", size: "medium" },
                                  },
                                  [_vm._v("已支付")]
                                )
                              : _c(
                                  "el-tag",
                                  { attrs: { type: "danger", size: "medium" } },
                                  [_vm._v("未支付")]
                                ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "address", label: "线路", width: "230" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " + _vm._s(scope.row.express_title || "-") + " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "weight", label: "重量(kg)", width: "120" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.weight
                              ? _c("div", [
                                  _c("p", [
                                    _vm._v(
                                      _vm._s(
                                        (scope.row.weight / 1000).toFixed(3)
                                      )
                                    ),
                                  ]),
                                ])
                              : _c("div", [_vm._v("-")]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "width", label: "尺寸(cm)" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.length
                              ? _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(scope.row.length) +
                                      "*" +
                                      _vm._s(scope.row.width) +
                                      "*" +
                                      _vm._s(scope.row.height) +
                                      " "
                                  ),
                                ])
                              : _c("span", [_vm._v("-")]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "channel_name", label: "下游渠道" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " + _vm._s(scope.row.channel_name || "-") + " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "stuff_box_time",
                      label: "装箱时间",
                      width: "180",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.stuff_box_time
                              ? _c("div", [
                                  _c("p", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatDate")(
                                          scope.row.stuff_box_time
                                        )
                                      )
                                    ),
                                  ]),
                                ])
                              : _c("div", [_vm._v("-")]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "address", label: "操作", width: "90" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm.boxData.status == 2
                              ? _c(
                                  "z-button",
                                  {
                                    attrs: {
                                      type: "danger",
                                      size: "small",
                                      plain: "",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.voidItem(scope)
                                      },
                                    },
                                  },
                                  [
                                    _c("i", { staticClass: "el-icon-delete" }),
                                    _vm._v("删除"),
                                  ]
                                )
                              : _c("span", [_vm._v("-")]),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
      _c("div", { staticClass: "ctrl-btn" }, [
        _c("div", { staticClass: "total" }, [
          _vm._v(
            " 共计：" +
              _vm._s(_vm.list.length || "-") +
              "个包裹; 总重量：" +
              _vm._s(_vm.packageWeight / 1000 || "-") +
              " kg "
          ),
        ]),
      ]),
      _c("audio", {
        ref: "audio-success",
        attrs: {
          src: "//res-cn.public.gblinker.com/gblinker/ops_assets/in-box.mp3",
        },
      }),
      _c("audio", {
        ref: "audio-fail",
        attrs: {
          src: "//res-cn.public.gblinker.com/gblinker/ops_assets/failed.mp3",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }